
<template>
    <form>
      <div class="flex items-end">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Personal Information</span>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mt-4">
          <label class="text-sm">Country</label>
          <v-select v-model="data.country_id" label="name" 
          v-validate="{ required: true}" :reduce="item => item.id"  name="country" placeholder="--select--" 
          :closeOnSelect="true" :options="countries" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
          <span class="text-danger text-sm"  v-show="errors.has('country')">{{ errors.first('country') }}</span>
        </div>
        <div class="vx-col md:w-1/2 w-full mt-4">
          <label class="text-sm">Mobile ({{getPhoneCode}})</label>
          <vs-input class="w-full" v-maska="'(###) ###-####'" v-model="data.mobile" v-validate="{required: true }" placeholder="mobile" name="mobile" />
          <span class="text-danger text-sm"  v-show="errors.has('mobile')">{{ errors.first('mobile') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
           <vs-input class="w-full" label="Street address" v-model="data.address"
           v-validate="'required'" placeholder="Street address" name="address" />
          <span class="text-danger text-sm"  v-show="errors.has('address')">{{ errors.first('address') }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-4">
           <vs-input class="w-full" label="City" v-model="data.city"
           v-validate="'required'" placeholder="City" name="city" />
          <span class="text-danger text-sm"  v-show="errors.has('city')">{{ errors.first('city') }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-4">
           <vs-input class="w-full" label="State" v-model="data.state" 
           v-validate="'required'" placeholder="State" name="state" />
          <span class="text-danger text-sm"  v-show="errors.has('state')">{{ errors.first('state') }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-4">
           <vs-input class="w-full" label="Zip code" v-model="data.zip" 
           v-validate="'required'" placeholder="zip code" name="zip" />
          <span class="text-danger text-sm"  v-show="errors.has('zip')">{{ errors.first('zip') }}</span>
        </div>
        <div class="vx-col w-full md:w-1/2 mt-4">
          <label class="text-sm">Birth Date</label>
          <flat-pickr v-model="data.dob" :config="{ dateFormat: 'd F Y', maxDate: new Date() }"
           class="w-full" placeholder="date of birth" name="dob" />
          <span class="text-danger text-sm"  v-show="errors.has('dob')">{{ errors.first('dob') }}</span>
        </div>
        <div v-if="!minimal" class="vx-col w-full mt-4">
           <div class="mt-6">
            <vs-textarea label="About" rows="3" name="about" v-validate="'max:10000'" v-model="data.about" placeholder="Enter bio..." />
            <span class="text-danger text-sm"  v-show="errors.has('about')">{{ errors.first('about') }}</span>
          </div>
        </div>
      </div>
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-2 flex flex-wrap items-center justify-end">
            <vs-button type="filled" @click.prevent="updateUser()" class="mt-2 block">Update</vs-button>
          </div>
        </div>
      </div>
    </form>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
export default {
  props:{
    minimal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    flatPickr
  },
  data() {
    return {
      data:{
        mobile: "",
        dob: null,
        country_id: "",
        address: "",
        city: "",
        state: "",
        zip: null,
        about: "",
      },
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    countries(){
      return this.$store.state.countries;
    },
    getPhoneCode(){
      const defaultCode = "+xxx";
      if(!this.data.country_id) return "+xxx"
      const country = this.countries.find(i => i.id ==  this.data.country_id)
      return country ? country.phone_code : defaultCode;
    }
  },
  methods: {
    updateUser() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$vs.loading({ color: "#444", type: "sound"})
          this.$http.post(`/account/update-info`, formData)
          .then((response) => {
            this.$vs.loading.close()
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO', response.data.data)
              this.$emit("closeMe")
            }
          })
        }
      })
    },
    patchUser(){
      const address = this.activeUser.address || {}
      this.data = this.$options.filters
                      .patchUpdatables(this.data, {...this.activeUser, ...address})
      this.data.dob = this.data.dob ? new Date(this.data.dob) : null
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
